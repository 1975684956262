<template>
  <div id="publication-list">
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <CollectionForm
        v-model="item"
        :is-form-active="isFormActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Paiements') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </CollectionForm>
    </v-dialog>
    <v-dialog
      v-model="isFormAttributionActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <AttributionForm
        v-model="item"
        :is-form-active="isFormAttributionActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Attribuer le dossier') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormAttributionActive = !isFormAttributionActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormAttributionActive = !isFormAttributionActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </AttributionForm>
    </v-dialog>

    <v-dialog
      v-model="isPaiementAuteurActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <paiement-auteur-form
        v-if="isPaiementAuteurActive"
        :is-form-active="isPaiementAuteurActive"
        :selected="selectedRows"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Modifier le paiement auteur')}}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isPaiementAuteurActive = !isPaiementAuteurActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isPaiementAuteurActive = !isPaiementAuteurActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </paiement-auteur-form>
    </v-dialog>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Paiements") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
        <v-spacer /> <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
        <Actions
          :headers="filters.filter(ele => ele.group && $can('edit_' + ele.value, 'Paiment'))"
          :selected="selectedRows"
          completed-field="publication.state"
          :show-buttons="true"
          :config="{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Paiment' }"
          @refetch-data="fetchItems"
          @saved="saved"
          @select="select"
        />
        <v-btn
          v-if="$can('edit_author_payment','Paiment')"
          color="primary"
          class="ml-1 "
          :disabled="!selectedRows.length"
          @click.stop="openPaiementAuteurDialog(false)"
        >
          <v-icon>{{ icons.mdiCalendar }}</v-icon>
          <span>{{ $t("paiement auteur") }}</span>
        </v-btn>
      </v-card-title>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        :mobile-breakpoint="250"
        hide-default-footer
        :show-select="true"
        dense fixed-header :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" :show-select="true" />
        </template>
        <template #item="options">
          <Row :options="options" :show-select="true" @select="select"
               :config="{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Paiment' }"
               @refetch-data="fetchItems"
               @saved="saved">
        <template #[`item.completion_date`]="{item}">
          <span v-if="item.completion_date && !item.completion_date.includes('0000')">{{ formatDate(item.completion_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
        </template>
        <template #[`item.is_reading1`]="{item}">
         <span>
                <v-icon  v-if="item.is_reading" small color="success">
                {{ icons.mdiCheckCircle }}
              </v-icon>
              </span>
        </template>
        <!-- name -->
        <template #[`item.author.full_name`]="{item}">
          <div class="d-flex align-center" v-if="item.author && item.author.id">
            <v-avatar
              :color="item.author.avatar ? '' : 'primary'"
              :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="30"
            >
              <v-img
                v-if="item.author.avatar"
                :lazy-src="require(`@/assets/images/avatars/2.png`)"
                :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.author.full_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'author-view', params : { id : item.author_id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.author.full_name }}
              </router-link>
              <!--              <small>@{{ item.publicationname }}</small>-->
            </div>
          </div>
        </template>
        <template #[`item.author.old_author`]="{item}">
          <v-icon
            v-if="item.author && item.author.old_author"
            small
            color="success"
          >
            {{ icons.mdiCheckCircle }}
          </v-icon>
        </template>
        <template #[`item.author.published`]="{item}">
          <div class="text-center">
            <v-icon
              v-if="item.published"
              small
              color="success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </div>
        </template>
        <!-- pay -->
        <template #[`item.country`]="{item}">
          <div
            v-if="item.country"
            class="d-flex align-center"
          >
            <span class="text-capitalize">{{ item.country.name }}</span>
          </div>
        </template>

        <!-- status -->
        <template #[`item.author_payment1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'author_payment')">
            <template>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, action: 'paiement_author_payment',activity_date: item.author_payment,date_column:'author_payment', 'activity_date_label': $t('Paiement auteur'), info: $t('Confirmez-vous que le paiement auteur a été reçu ?') })"
                  v-on="on"
                ><span v-if="item.author_payment">{{ new Date(item.author_payment).toLocaleDateString( 'fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                </template>
                <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'paiement_author_payment').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                <template v-else>
                  <div>
                    <p
                      v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_author_payment')"

                      :key="precord.id"
                    >
                      <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                      <span
                        v-if="precord.date_record"
                        class="mr-2"
                      >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                      <span class="mr-2">{{ precord.comment }}</span>
                    </p>
                  </div>
                </template>
              </v-tooltip>
            </template>
            <v-tooltip
              v-if="!item.author_payment"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog({...item, action: 'paiement_author_payment', activity_date: item.author_payment,date_column:'author_payment', 'activity_date_label': $t('Paiement auteur'), info: $t('Confirmez-vous que le paiement auteur a été reçu ?') })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
            </v-tooltip>
          </div>
        </template>

        <template #[`item.payment_conditions1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'payment_conditions')">
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, column_type: 'select', items: paymentMethods, selectId:'id', selectLabel: 'name', action: 'paiement_payment_conditions',activity_date: item.payment_conditions,date_column:'payment_conditions', 'activity_date_label': $t('Conditions paiement'), info: $t('Veuillez indiquer la condition des paiements ci-dessous') })"
                  v-on="on"
                ><span v-if="item.payment_conditions">{{ item.payment_conditions }}</span></span>
              </template>
              <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'paiement_payment_conditions').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_payment_conditions')"

                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span
                      v-if="precord.date_record"
                      class="mr-2"
                    >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          </template>
          <v-tooltip
            v-if="!item.payment_conditions"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
                @click="openDialog({...item, column_type: 'select', items: paymentMethods.filter(ele => ele.id), selectId:'id', selectLabel: 'name', action: 'paiement_payment_conditions', activity_date: item.payment_conditions,date_column:'payment_conditions', 'activity_date_label': $t('Conditions paiement'), info: $t('Veuillez indiquer la condition des paiements ci-dessous') })"
              >
                <v-icon color="primary">
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
          </v-tooltip>
          </div>
        </template>

        <template #[`item.first_payment_africa1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'first_payment_africa')">
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, action: 'paiement_first_payment_africa',activity_date: item.first_payment_africa,date_column:'first_payment_africa', 'activity_date_label': $t('1er paiement Afrique'), info: $t('Confirmez-vous que le commande exempl. auteur a été faite ?') })"
                  v-on="on"
                ><span v-if="item.first_payment_africa">{{ new Date(item.first_payment_africa).toLocaleDateString( 'fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
              </template>
              <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'paiement_first_payment_africa').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_first_payment_africa')"

                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span
                      v-if="precord.date_record"
                      class="mr-2"
                    >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          </template>
          <v-tooltip
            v-if="!item.first_payment_africa"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
                @click="openDialog({...item, action: 'paiement_first_payment_africa', activity_date: item.first_payment_africa,date_column:'first_payment_africa', 'activity_date_label': $t('1er paiement Afrique'), info: $t('Confirmez-vous que le commande exempl. auteur a été faite ?') })"
              >
                <v-icon color="primary">
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
          </v-tooltip>
          </div>
        </template>

        <template #[`item.second_payment_africa1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'second_payment_africa')">
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, action: 'paiement_second_payment_africa',activity_date: item.second_payment_africa,date_column:'second_payment_africa', 'activity_date_label': $t('2ème paiement Afrique'), info: $t('Confirmez-vous que le 2ème paiement Afrique a été effectué?') })"
                  v-on="on"
                ><span v-if="item.second_payment_africa">{{ new Date(item.second_payment_africa).toLocaleDateString( 'fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
              </template>
              <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'paiement_second_payment_africa').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_second_payment_africa')"

                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span
                      v-if="precord.date_record"
                      class="mr-2"
                    >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          </template>
          <v-tooltip
            v-if="!item.second_payment_africa"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
                @click="openDialog({...item, action: 'paiement_second_payment_africa', activity_date: item.second_payment_africa,date_column:'second_payment_africa', 'activity_date_label': $t('2ème paiement Afrique'), info: $t('Confirmez-vous que le 2ème paiement Afrique a été effectué??') })"
              >
                <v-icon color="primary">
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
          </v-tooltip>
          </div>
        </template>

        <template #[`item.date_2nd_sending1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'date_2nd_sending')">
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, action: 'paiement_date_2nd_sending',activity_date: item.date_2nd_sending,date_column:'date_2nd_sending', 'activity_date_label': $t('Date 2nd envoi'), info: $t('Veuillez indiquer la date du deuxième envoi') })"
                  v-on="on"
                ><span v-if="item.date_2nd_sending">{{ new Date(item.date_2nd_sending).toLocaleDateString( 'fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
              </template>
              <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'paiement_date_2nd_sending').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_date_2nd_sending')"

                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span
                      v-if="precord.date_record"
                      class="mr-2"
                    >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          </template>
          <v-tooltip
            v-if="!item.date_2nd_sending"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
                @click="openDialog({...item, action: 'paiement_date_2nd_sending', activity_date: item.date_2nd_sending,date_column:'date_2nd_sending', 'activity_date_label': $t('2ème paiement Afrique'), info: $t('Veuillez indiquer la date du deuxième envoi') })"
              >
                <v-icon color="primary">
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
          </v-tooltip>
            </div>
        </template>

        <template #[`item.author_copy_order1`]="{item}">
          <div :style="stateColor(item.publication_colors, 'author_copy_order')">
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  @click="openDialog({...item, action: 'paiement_author_copy_order',activity_date: item.author_copy_order,date_column:'author_copy_order', 'activity_date_label': $t('Cde exempl. auteur'), info: $t('Confirmez-vous que le commande exempl. auteur a été faite ?') })"
                  v-on="on"
                ><span v-if="item.author_copy_order">{{ new Date(item.author_copy_order).toLocaleDateString( 'fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
              </template>
              <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'paiement_author_copy_order').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
              <template v-else>
                <div>
                  <p
                    v-for="precord in item.publication_records.filter(el=>el.type === 'paiement_author_copy_order')"

                    :key="precord.id"
                  >
                    <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                    <span
                      v-if="precord.date_record"
                      class="mr-2"
                    >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                    <span class="mr-2">{{ precord.comment }}</span>
                  </p>
                </div>
              </template>
            </v-tooltip>
          </template>
          <v-tooltip
            v-if="!item.author_copy_order"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
                @click="openDialog({...item, action: 'paiement_author_copy_order', activity_date: item.author_copy_order,date_column:'author_copy_order', 'activity_date_label': $t('Cde exempl. auteur'), info: $t('Confirmez-vous que le commande exempl. auteur a été faite ?') })"
              >
                <v-icon color="primary">
                  {{ icons.mdiCheckboxMarkedCircleOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
          </v-tooltip>
          </div>
        </template>

            <template #[`item.order_copies_bnf1`]="{item}">
              <div v-if="isPublicationbod(item)" :style="stateColor(item.publication_colors, 'order_copies_bnf')">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'publication_order_copies_bnf',activity_date: item.order_copies_bnf,date_column:'order_copies_bnf', 'activity_date_label': $t('Cde exemplaires BNF'), info: $t('Confirmez-vous que le Cde exemplaires BNF a été fait?') })"
                    v-on="on"
                  ><span v-if="item.order_copies_bnf && !item.order_copies_bnf.includes('0000')">{{ formatDateShort(item.order_copies_bnf || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'publication_order_copies_bnf').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'publication_order_copies_bnf')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span
                            v-if="precord.date_record"
                            class="mr-2"
                          >{{ formatDateShort(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.order_copies_bnf || item.order_copies_bnf.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openDialog({...item, action: 'publication_order_copies_bnf',activity_date: item.order_copies_bnf,date_column:'order_copies_bnf', 'activity_date_label': $t('Cde exemplaires BNF'), info: $t('Confirmez-vous que le Cde exemplaires BNF a été fait?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour mettre à jour') }}</span>
                </v-tooltip>
              </div>
            </template>





        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <!--          <v-btn
            color="primary"
            small
            icon
            @click="openDialogAttribution({...item, publication_id: item.id, gestion_id:null, assistant_id: null, task_date: null })"
          >
            <v-icon
              class="me-2"
              color="primary"
            >
              {{ icons.mdiCalendar }}
            </v-icon>
          </v-btn>-->
        </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCalendar,
  mdiCheckboxMarkedCircleOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import itemStoreModule from './itemStoreModule'
import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import CollectionForm from '@/views/apps/publications/SignatureForm'
import AttributionForm from '@/views/apps/publications/AttributionForm'
import HeaderFilters from '@/components/HeaderFilters'
import ability from '@/plugins/acl/ability'
import TableRow from '@/components/TableRow'
import Row from "@/components/Row";
import PaiementAuteurForm from "@/views/apps/publications/PaiementAuteurForm";
import Actions from "@/components/Actions";

export default {
  components: {
    Actions,
    PaiementAuteurForm,
    Row,
    TableRow,
    HeaderFilters,
    AttributionForm,
    CollectionForm,
    RowsPerPage,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      saved,
      select,
      item,
      items,
      tableCommonColumns,
      paymentTableColumns: tableColumns,
      openPaiementAuteurDialog,
      isPaiementAuteurActive,
      filters,
      tabFilter,
      tableHead,
      paymentMethods,
      publicationStates,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      totalItems,
      loading,
      options,
      publicationTotalLocal,
      selectedRows,
      isFormActive,
      isFormAttributionActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      isPublicationbod,
      openDialog,
      openDialogAttribution,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems,tableHeight,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
    } = controller()

    const superiors = ref([])
    const assistants = ref([])

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Publication', value: 'publication' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]
    store
      .dispatch('app-publication/fetchCountries', { per_page: 500 })
    store
      .dispatch('app-publication/fetchSources', { per_page: 500 })
    store
      .dispatch('app/fetchGenres', { per_page: 500 })
    store
      .dispatch('app/fetchFormats', { per_page: 500 })
    store
      .dispatch('app-publication/fetchStatuses', { per_page: 500, status_type: 'publication' })

    stateFilter.value = [
      publicationStates.value.PUBLICATION_STATE/*, publicationStates.value.RESILIATION_STATE, publicationStates.value.PAYMENT_STATE*/]
    /* tabFilter.value = 'text' */
    /* filters.value = tableCommonColumns */
    filters.value.push(...tableColumns.filter(ele => !ele.value || ability.can('view_' + ele.value, 'Paiment')))

    /* filters.value.push(
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ) */

    axios
      .get('/listWorkers', { params: { term: '', /* user: item.value.gestion_id, */ rowsPerPage: 100 } })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistants.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistants.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistants.value
        }
      })

    const formatDateShort = (date, option) => formatDate(date, option)

    return {
      select,
      saved,
      item,
      items,
      superiors,
      tableColumns,
      searchQuery,
      tabFilter,
      filters,
      payFilter,
      sourceFilter,
      statusFilter,
      stateFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      assistants,
      publicationTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isFormAttributionActive,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openPaiementAuteurDialog,
      isPaiementAuteurActive,
      openDialog,
      isPublicationbod,
      openDialogAttribution,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      formatDateShort,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      fetchItems,tableHeight,
      paymentMethods,
      tableHead,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCalendar,
        mdiCheckboxMarkedCircleOutline,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
